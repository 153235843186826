import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query MyQuery {
        site(siteMetadata: {}) {
          siteMetadata {
            address {
              head_office {
                country
                post_code
                shop
                state
                street
                suburb
              }
            }
            business_numbers {
              abn
              qbcc
            }
            description
            email {
              accounts
              contact
              marketing
              sales
            }
            phone {
              mobile {
                label
                number
              }
              national {
                label
                number
              }
            }
            social_media {
              facebook
              instagram
              pinterest
              twitter
            }
            siteUrl
            title
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
