import PropType from 'prop-types';
import React from 'react';
import useSiteMetadata from '../../hooks/gatsby/site-meta-data';

const SEO = (props) => {
  const {
    children,
    description: pageDescription,
    location,
    title: pageTitle,
  } = props;
  const { title, siteUrl, description, social_media } = useSiteMetadata();
  const pathUrl =
    location.pathname === '/' ? siteUrl : `${siteUrl}/${location.pathname}`;

  //  TODO: add keywords

  return (
    <>
      {pageTitle === '' && <title>{title}</title>}
      {pageTitle !== '' && <title>{`${pageTitle} | ${title}`}</title>}
      <link id="icon" rel="icon" href="global-icon" />
      <meta name="description" content={pageDescription || description} />
      {/* the following line is added by gatsby  so no need to do it again (?) */}
      {/* <meta name="viewport" content="initial-scale=1, width=device-width" /> */}
      {/* https://stackoverflow.com/questions/74280628/website-on-ios-chrome-and-safari-too-wide */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      {/* {location.pathname === '/' && (
        <meta name="twitter:url" content={`${siteUrl}`} />
      )}
      {location.pathname !== '/' && (
        <meta name="twitter:url" content={`${siteUrl}/${location.pathname}`} />
      )} */}
      {/* Open Graph - START */}
      <meta property="og:title" content={pageTitle || title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={pathUrl} />
      {/* <meta
        property="og:image"
        content="https://ia.media-imdb.com/images/rock.jpg"
      /> */}
      {/* Open Graph - END */}
      {children}
    </>
  );
};

SEO.defaultProps = {
  title: '',
};

SEO.propTypes = {
  location: PropType.shape({
    pathname: PropType.string.isRequired,
  }).isRequired,
  title: PropType.string,
};

export default SEO;
